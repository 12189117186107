import './skip-link-focus-fix';

(function($) {



	// global alert with cookie
	$(document).ready(function () {
	//alert(GetCookie("dismissed-notifications"));
	//hide dismissed notifications
	if (GetCookie("dismissed-notifications")) {
		$(GetCookie("dismissed-notifications")).hide();
	}
	$(".dismiss-notification").on('click', function () {
		var alertId = $(this).closest(".alert-message").attr("id"); //get the id of the notification to be dismissed
		var dismissedNotifications = GetCookie("dismissed-notifications") + ",#" + alertId; //this is the new value of the dismissed notifications cookie with the array of ids
		$(this).closest(".alert-message").fadeOut('slow'); //dimsiss notification
		SetCookie("dismissed-notifications",dismissedNotifications.replace('null,','')) //update cookie
	});
	// Create a cookie with the specified name and value.
	function SetCookie(sName, sValue) {
		document.cookie = sName + "=" + escape(sValue);
		// Expires the cookie in one month
		var date = new Date();
		date.setMonth(date.getMonth()+1);
		document.cookie += ("; expires=" + date.toUTCString()); 
	}
	// Retrieve the value of the cookie with the specified name.
	function GetCookie(sName) {
		// cookies are separated by semicolons
		var aCookie = document.cookie.split("; ");
		for (var i=0; i < aCookie.length; i++) {
			// a name/value pair (a crumb) is separated by an equal sign
			var aCrumb = aCookie[i].split("=");
			if (sName == aCrumb[0]) 
		 		return unescape(aCrumb[1]);
			}
	  		// a cookie with the requested name does not exist
	  		return null;
		}
	});

	$(document).ready(function() {
		inputEffects();
		fullWidthVideo();
		fullWidthCardAccordion();
		menuToggle();
		headerEffects();
		searchToggle();

		$(window).on('scroll', function() {
			headerEffects();
		});

		$(window).scroll();
	});


	// Form input label effect
	function inputEffects() {
		$(".site-footer .ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea").focus(function(){
			var label = $(this).parent().prev('.gfield_label');
			label.addClass('gfield_label--focus');
		}).blur(function(){
			if(!$(this).val()) {
				var label = $(this).parent().prev('.gfield_label');
				label.removeClass('gfield_label--focus');
			}
		})

		$(document).bind('gform_post_render', function(){
			inputEffects();
			$(".site-footer .ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea").each(function() {
				if($(this).val()) {
					var label = $(this).parent().prev('.gfield_label');
					label.addClass('gfield_label--focus');
				}
			});
		});
	}
	
	// Full width video block
	function fullWidthVideo() {
		$('.full-video__overlay').on('click', function(e) {
			e.preventDefault();

			var video    = $(this).parent().siblings('.full-video__video'),
				videoSrc = video.attr('src');
				
			video.attr('src', videoSrc + '?autoplay=1');

			video.show();
		});
	}

	// Full width card block accordion
	function fullWidthCardAccordion() {
		$('.full-card__accordion-toggle').on('click', function(e) {
			e.preventDefault();

			var accordionContent = $(this).prev('.full-card__box').find('.full-card__accordion-content');

			accordionContent.slideToggle();
			$(this).toggleClass('full-card__accordion-toggle--open');
		});
	}

	// Header scroll effects
	function headerEffects() {
		var navbar = $('#masthead');

		var scrollPosition = $(window).scrollTop();

		if(scrollPosition > 100)
			navbar.addClass('site-header--scrolled');
		else
			navbar.removeClass('site-header--scrolled');
	}

	// Header menu toggle
	function menuToggle() {
		$("#menu-toggle").on('click', function() {
			$(this).toggleClass('site-header__menu-toggle--open');
			$("#menu-primary-menu").toggleClass('site-header__menu--open');
		});

		$(".submenu-toggle").on('click', function() {
			var submenu = $(this).next('.sub-menu');
			$(this).toggleClass('submenu-toggle--open');
			submenu.slideToggle();
		});
	}

	// Header search toggle
	function searchToggle() {
		var header = $('#masthead');

		$("#search-toggle").on('click', function() {
			header.toggleClass('site-header--search-open');
			$("#header-search").focus();
		});

		$('body').on('click', function(event) {
			if( !$(event.target).is('#search-toggle, #header-search') ) {
				header.removeClass('site-header--search-open');
			}
		});
	}

})(jQuery);